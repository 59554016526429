<template>
  <div>
    <div class="container">
      <div class="logo-cont">
        <img class="logo" src="../../assets/image/rwa/binding/logo.png">
      </div>

      <div class="qr-image-cont">
        <div class="qr-img">
          <img :src="QRImgUrl"/>
        </div>

        <div class="tips">
          {{$t('扫一扫')}}BURNING RWA
        </div>
      </div>
    </div>

    <!--    <div class="bottom-cont">-->
    <!--      <div class="confirm-cont">-->
    <!--        <div class="confirm-but" style="float: left">保存图片</div>-->
    <!--        <div class="confirm-but" style="float: right">确定</div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import QRCode from "qrcode";

export default {
  name: "shareImage",
  data() {
    return {
      QRImgUrl: '',
      codeData: null
    }
  },
  created() {
    let link = window.location.href;
    this.getQRCode(link)
  },
  methods: {
    getQRCode(text) {
      if (text) {
        QRCode.toDataURL(text, {errorCorrectionLevel: 'L', margin: 0.5, width: 80}, (err, url) => {
          if (err) throw err
          this.QRImgUrl = url
        })
      }
    },
    copyLink() {
      let that = this
      let link = window.location.href;
      this.$copyText(link).then(
          function (e) {
            that.$toast(that.$t('复制成功'));
          }
      );
    },
  }
}
</script>

<style scoped lang="less">

.container {
  position: relative;
  height: 90vh;
  background: url("../../assets/image/rwa/binding/share-bg.png") no-repeat;
  background-position: top;
  background-size: 100%;
}

.logo-cont {
  position: absolute;
  width: 100%;
  top: 34.5vh;
  text-align: center;

  .logo {
    width: 8.11rem;
  }
}

.bottom-cont {
  position: absolute;
  z-index: 2;
  width: 100%;
  left: 0;
  bottom: 0;

}

.title {
  padding-left: 2.39rem;
  margin-bottom: 0.32rem;
  font-size: 1rem;
  color: #E7BD71;
}

.input-cont {
  margin: 1rem;
  padding: 1rem 1.36rem;
  background: #1B1B1B;
  border-radius: 1.57rem;

  font-size: 1rem;
  color: #E7BD71;

  span {
    padding-right: 2rem;
    color: #B5904E;
  }
}


.confirm-cont {
  margin-top: 8vh;
  padding: 1rem 0.9rem;
  overflow: hidden;

  .confirm-but {
    width: 45%;
    text-align: center;
    line-height: 3.14rem;
    background: linear-gradient(270deg, #E8BD70 0%, #EDD185 100%);
    border-radius: 1.57rem;
    font-size: 1.29rem;
    color: #000000;
  }
}

.qr-image-cont {
  position: absolute;
  top: 60vh;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;

  .qr-img {
    display: inline-block;
    padding: 1.2rem;
    background: url("../../assets/image/bank/qr-bg.png") no-repeat;
    background-size: cover;

    img {
      width: 5rem;
    }
  }
}

.tips {
  padding: 1rem 0;
  font-size: 0.79rem;
  text-align: center;
  color: #6E6045;
  line-height: 1.5rem;
}
</style>
